import React, { useEffect, useState } from "react";
import SubscribeSection from "../Homepage/SubscribeSection";
import { Link } from "react-router-dom";
import {
  getAllOrders,
  getOrderInvoiceId,
  getOrderReportId,
} from "../../services/Apiservices";
import ReactPaginate from "react-paginate";

function MyOrderList() {
  const [orderList, setOrderList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getOrders();
  }, [currentPage]);

  const getOrders = async () => {
    setLoading(true);
    try {
      let response = await getAllOrders(currentPage * 12);
      if (response?.status == 200) {
        setOrderList(response?.data);
        setLoading(false);
        setPageCount(Math.floor(response?.data?.count / 12));
      }
    } catch (error) {
      setLoading(false);

      setOrderList(null);
    }
  };

  const handleShowStatus = (key) => {
    switch (key) {
      case 1:
        return "Not Started";
      case 2:
        return "Processing";
      case 3:
        return "Completed";
      case 4:
        return "Failed";
      case 5:
        return "Incompleted";

      default:
        return "N/A";
    }
  };

  const handleDownloadReport = async (id) => {
    try {
      let response = await getOrderReportId(id);
      if (response?.status == 200) {
        // Assuming response.data contains the Excel file data
        const blob = new Blob([response?.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }); // Mime type for Excel (XLSX) files

        const url = window.URL.createObjectURL(blob);

        // Create a temporary <a> element to initiate download
        const link = document.createElement("a");
        link.href = url;
        link.download = `order_${id}_report.xlsx`;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
      }
    } catch (error) {}
  };
  const handleDownloadInvoice = async (id) => {
    try {
      let response = await getOrderInvoiceId(id);
      if (response?.status == 200) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary <a> element to initiate download
        const link = document.createElement("a");
        link.href = url;
        link.download = `order_${id}_invoice.pdf`;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
      }
    } catch (error) {}
  };
  const options = {
    hour12: true, day: 'numeric', month: 'short', year: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
};
  return (
    <>
      <div className="main_content mt-3">
        {/* START SECTION SHOP */}
        <div className="section py-3 my-3" style={{ minHeight: "60vh" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="">
                  <div className=" ">
                    <h3 className=" text-center">My Orders</h3>
                    <span
                      className="d-inline border px-3 bg-white"
                      style={{ float: "right" }}
                    >
                      {/* <div>
                        <span className="text-dark price">Complete</span> : 0
                      </div>
                      <div>
                        <span className="text-dark price">In-Complete</span> : 1
                      </div> */}
                    </span>
                  </div>
                  <div className="">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th
                              style={{
                                backgroundColor: "#F7F7F7 ",
                                borderTopLeftRadius: "15px",
                                borderRight: "1px solid",
                              }}
                              className="text-center "
                            >
                              Order
                            </th>
                            <th
                              style={{
                                backgroundColor: "#F7F7F7 ",
                                borderRight: "1px solid",
                              }}
                              className="text-center"
                            >
                              Date & Time
                            </th>
                            <th
                              style={{
                                backgroundColor: "#F7F7F7 ",
                                borderRight: "1px solid",
                              }}
                              className="text-center "
                            >
                              Status
                            </th>
                            <th
                              style={{
                                backgroundColor: "#F7F7F7 ",
                                borderRight: "1px solid",
                              }}
                              className="text-center "
                            >
                              Brand
                            </th>
                            <th
                              style={{
                                backgroundColor: "#F7F7F7 ",
                                borderRight: "1px solid",
                              }}
                              className="text-center"
                            >
                              <small>Card Value</small>
                            </th>
                            <th
                              style={{
                                backgroundColor: "#F7F7F7 ",
                                borderRight: "1px solid",
                              }}
                              className="text-center "
                            >
                              Qty
                            </th>
                            <th
                              style={{
                                backgroundColor: "#F7F7F7 ",
                                borderRight: "1px solid",
                              }}
                              className="text-center "
                            >
                              Total
                            </th>
                            <th
                              style={{
                                backgroundColor: "#F7F7F7 ",
                                borderTopRightRadius: "15px",
                              }}
                              className="text-center"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>

                        {orderList?.results?.length !== 0 ? (
                          <>
                            <tbody>
                              {orderList?.results?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className=" text-center ">
                                      #cr_00{item?.id}
                                    </td>
                                    <td className="text-center ">
                                      <small>
                                      {new Date(item?.created_on).toLocaleString(
                                                                    undefined,
                                                                    options
                                                                )}
                                        </small>
                                    </td>
                                    <td className="text-center ">
                                      <span>
                                        <small
                                          className="rounded-pill px-2 p-1"
                                          style={{
                                            backgroundColor:
                                              handleShowStatus(
                                                item?.order_status
                                              ) == "Completed"
                                                ? "#2dc225"
                                                : "#f74848",
                                            color:
                                              handleShowStatus(
                                                item?.order_status
                                              ) == "Completed"
                                                ? "#d9fcd7 "
                                                : "#ffdcd6",
                                            fontSize: "12px",
                                            fontWeight: "700",
                                          }}
                                        >
                                          {handleShowStatus(item?.order_status)}
                                        </small>
                                      </span>
                                    </td>
                                    <td className="text-center ">
                                      <small className="text-muted ">
                                        {item?.provider_name}
                                      </small>
                                    </td>
                                    <td className="price text-dark text-center ">
                                      {item?.denomination}
                                    </td>
                                    <td className="text-center ">
                                      {item?.quantity}
                                    </td>
                                    <td className="price text-center">
                                      ₹ {item?.denomination * item?.quantity}{" "}
                                      <small></small>
                                    </td>
                                    {item?.order_status == 3 && (
                                      <td className="d-flex justify-content-evenly ">
                                        <>
                                          {" "}
                                          <span
                                            onClick={() =>
                                              handleDownloadInvoice(item?.id)
                                            }
                                            style={{
                                              backgroundColor: "#FF324D",
                                              color: "white",
                                              cursor: "pointer",
                                            }}
                                            className=" px-2 py-1 rounded-pill"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="15"
                                              height="16"
                                              viewBox="0 0 15 16"
                                              fill="none"
                                            >
                                              <path
                                                d="M13.125 9.875V12.375C13.125 12.7065 12.9933 13.0245 12.7589 13.2589C12.5245 13.4933 12.2065 13.625 11.875 13.625H3.125C2.79348 13.625 2.47554 13.4933 2.24112 13.2589C2.0067 13.0245 1.875 12.7065 1.875 12.375V9.875"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M4.375 6.75L7.5 9.875L10.625 6.75"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M7.5 9.875V2.375"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>{" "}
                                            Invoice
                                          </span>
                                          <span
                                            onClick={() =>
                                              handleDownloadReport(item?.id)
                                            }
                                            style={{
                                              backgroundColor: "#58AB7F",
                                              color: "white",
                                              cursor: "pointer",
                                            }}
                                            className="px-2 py-1 rounded-pill "
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="15"
                                              height="16"
                                              viewBox="0 0 15 16"
                                              fill="none"
                                            >
                                              <path
                                                d="M13.125 9.875V12.375C13.125 12.7065 12.9933 13.0245 12.7589 13.2589C12.5245 13.4933 12.2065 13.625 11.875 13.625H3.125C2.79348 13.625 2.47554 13.4933 2.24112 13.2589C2.0067 13.0245 1.875 12.7065 1.875 12.375V9.875"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M4.375 6.75L7.5 9.875L10.625 6.75"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                              <path
                                                d="M7.5 9.875V2.375"
                                                stroke="white"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>{" "}
                                            Voucher
                                          </span>
                                        </>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </>
                        ) : (
                          <></>
                        )}
                      </table>
                    </div>
                    {orderList?.count > 12 && (
                      <div className="row">
                        <div className="col-12 mt-2 mt-md-4 ">
                          <ReactPaginate
                            className="pagination pagination_style1 justify-content-center"
                            previousLabel="Prev"
                            breakLabel="..."
                            pageCount={pageCount}
                            onPageChange={({ selected }) =>
                              setCurrentPage(selected + 1)
                            }
                            containerClassName="pagination"
                            activeClassName="active"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END SECTION SHOP */}
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
        <SubscribeSection />
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
      </div>
      {loading && (
        <div className="preloader">
          <div className="lds-ellipsis">
            <span />
            <span />
            <span />
          </div>
        </div>
      )}
    </>
  );
}

export default MyOrderList;
