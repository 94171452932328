import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { restrictAlpha } from "../constants";
import {
  googleLogin,
  register,
  sendOtp,
  verifyOtp,
} from "../services/Apiservices";

import { setMobileNumber } from "../redux/reducers/mobileNumberSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { login } from "../redux/reducers/authSlice";
import { setUser } from "../redux/reducers/userSlice";
import { setSession } from "../session";
import showNotification from "../services/NotificationService";
import { loginShow, signUpHide } from "../redux/reducers/modalSlice";

function SignupModal() {

  const dispatch = useDispatch();

  const [loading, setisLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const mobileNumber = useSelector((state) => state.mobileNumber);
  const showSignup = useSelector((state) => state?.modalView?.showSignup);
  const [otpSent, setOTPSent] = useState(true);
  const [timeLeft, setTimeLeft] = useState(5 * 60);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Mobile number must contain only digits")
      .min(10, "Mobile number must be at least 10 digits")
      .required("Mobile number is required"),
    agreeToTerms: Yup.boolean().oneOf(
      [true],
      "You must agree to the terms and policy to proceed"
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      gst_no: "",
      agreeToTerms: false,
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      handleRegister(values);
    },
  });

  useEffect(() => {
    let interval;

    if (otpSent) {
      interval = setInterval(() => {
        setTimeLeft((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            setOTPSent(false);
            return 5 * 60; // Reset the timer to initial value when it reaches 0
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [otpSent]);

  const handleRegister = async (values) => {
    setisLoading(true);
    let body = {
      email: values?.email,
      mobile_no: values?.mobile,
      full_name: values?.name,
      gst_no: values?.gst_no,
    };
    try {
      let response = await register(body);
      if (response?.status == 200) {
        setTimeLeft(5 * 60);
        setisLoading(false);
        dispatch(setMobileNumber(values?.mobile));
        setShowOtp(true);
        // navigate("/verify-otp")
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const postData = new FormData();
      postData.append("access_token", credentialResponse.credential);

      const response = await googleLogin(postData);
      if (response.status === 200) {
        dispatch(signUpHide());

        showNotification("success", "SignUp Successfully");
        dispatch(login());
        dispatch(setUser(response?.data?.user));

        setSession(response?.data?.access);
        localStorage.setItem(
          "userDetails",
          JSON.stringify(response?.data?.user)
        );
        // closeModal();
        // navigate("/resumes");
        // setSession(response?.data?.access);
        // getUserProfilePic();
        // getUserProfileData();
      }
    } catch (error) {
      // ;
    }
  };

  const otpvalidationSchema = Yup.object({
    otp: Yup.string().required("OTP is required"),
  });

  const otpformik = useFormik({
    initialValues: {
      otp: "",
    },
    otpvalidationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      handleSubmitOtp();
    },
  });

  const handleSubmitOtp = async (otp) => {
    if (otp?.length == 6) {
      setisLoading(true);
      let body = {
        mobile: mobileNumber,
        otp: otp,
        device_token: "",
        device_type: "",
        device_name: "",
      };
      try {
        let response = await verifyOtp(body);
        if (response?.status == 200) {
          if (response?.data?.access) {
            setSession(response?.data?.access);
          }
          setisLoading(false);
          dispatch(signUpHide());
          dispatch(login());
          dispatch(setUser(response?.data?.details));
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response?.data?.details)
          );
          // navigate("/")
        } else {
          setisLoading(false);
        }
      } catch (error) {
        setisLoading(false);
      }
    }
  };
  const resendOtp = async () => {
    setisLoading(true);

    let body = {
      mobile: mobileNumber,
    };
    try {
      let response = await sendOtp(body);
      if (response?.status == 200) {
        setisLoading(false);
        setOTPSent(true);
      } else {
        setOTPSent(false);

        setisLoading(false);
      }
    } catch (error) {
      setOTPSent(false);

      setisLoading(false);
    }
  };
  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };
  return (
    <>
      <div
        className={
          showSignup
            ? "modal-backdrop  subscribe_popup show "
            : "modal fade subscribe_popup "
        }
        id="onload-popup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered "
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                onClick={() => dispatch(signUpHide())}
              >
                <span aria-hidden="true">
                  <i className="ion-ios-close-empty" />
                </span>
              </button>
              <div className="row g-0">
                <div className="col-sm-5">
                  <img
                    className="background_bg h-100 d-none d-sm-block"
                    src="../../assets/imgs/login.png"
                  />
                </div>
                <div className="col-sm-7">
                  <div className="popup_content">
                    {showOtp ? (
                      <div className="login_wrap">
                        <div className="padding_eight_all bg-white">
                          <div className="heading_s1 text-center">
                            <h3 className="d-inline">Verify OTP</h3>
                          </div>
                          <form
                            onSubmit={otpformik.handleSubmit}
                            className="mt-5 mb-5"
                            id="sign_up_form"
                          >
                            <div className="form-group mb-2 py-2 text-center">
                              <h5>
                                OTP Sent to <u>{mobileNumber}</u>{" "}
                                <Link
                                  title="Change Mobile No"
                                  style={{ padding: 3 }}
                                  onClick={() => setShowOtp(false)}
                                >
                                  <span className="ti-pencil" />
                                </Link>
                              </h5>
                            </div>
                            <div className="form-group mb-1 py-3">
                              <input
                                maxLength={6}
                                minLength={6}
                                name="otp"
                                defaultValue=""
                                placeholder="Enter OTP"
                                id="mobile_otp"
                                autoComplete="off"
                                onKeyPress={restrictAlpha}
                                type="text"
                                required=""
                                className="form-control input-bottom-line"
                                onChange={(e) => {
                                  otpformik?.setFieldValue(
                                    "otp",
                                    e.target.value
                                  );
                                  handleSubmitOtp(e.target.value);
                                }}
                                onBlur={otpformik.handleBlur}
                                value={otpformik.values.otp}
                              />
                              {otpformik.touched.otp && otpformik.errors.otp ? (
                                <div className="text-danger">
                                  {formik.errors.otp}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-note mt-0 my-3">
                              Didn't you receive any code?{" "}
                              {otpSent ? (
                                <Link>
                                  <span
                                    className="fs-7"
                                    style={{ color: "#4D4D4D" }}
                                  >
                                    Resend OTP {formatTime()}
                                  </span>
                                </Link>
                              ) : (
                                <Link onClick={resendOtp}>
                                  <span
                                    className="fs-7"
                                    style={{ color: "#594DF1" }}
                                  >
                                    Resend OTP
                                  </span>
                                </Link>
                              )}
                            </div>
                            <div className="form-group mb-3">
                              {/* <button
                              type="submit"
                              className="btn btn-fill-out btn-block"
                              name="login"
                            >
                              Verify OTP
                            </button> */}
                            </div>
                          </form>

                          {/* <div className="form-note text-center">
                          Don't Have an Account?{" "}
                          <Link to="/signup/">Sign up now</Link>
                        </div> */}
                        </div>
                      </div>
                    ) : (
                      <div className="login_wrap">
                        <div className="padding_eight_all bg-white">
                          <div className="heading_s1 text-center">
                            <h3>Create an Account</h3>
                          </div>
                          <form onSubmit={formik.handleSubmit}>
                            <div className="form-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                maxLength={50}
                                defaultValue=""
                                placeholder="Full Name"
                                id="full_name"
                                autoComplete="off"
                                required=""
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group mb-3">
                              <input
                                type="text"
                                required=""
                                className="form-control"
                                maxLength={80}
                                defaultValue=""
                                placeholder="Email"
                                id="user_email"
                                autoComplete="off"
                                name="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger">
                                  {formik.errors.email}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group mb-3">
                              <input
                                className="form-control"
                                minLength={10}
                                maxLength={10}
                                required=""
                                defaultValue=""
                                placeholder="Mobile No"
                                id="mobile_no"
                                autoComplete="off"
                                type="text"
                                onKeyPress={restrictAlpha}
                                name="mobile"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mobile}
                              />
                              {formik.touched.mobile && formik.errors.mobile ? (
                                <div className="text-danger">
                                  {formik.errors.mobile}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group mb-3">
                              <input
                                className="form-control"
                                maxLength={20}
                                name="gst_no"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.gst_no}
                                defaultValue=""
                                placeholder="Gst No (if applicable) - optional"
                                id="gst_no"
                                autoComplete="off"
                                type="text"
                              />
                            </div>
                            <div className="login_footer form-group mb-3">
                              <div className="chek-form">
                                <div className="custome-checkbox">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="agreeToTerms"
                                    name="agreeToTerms"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={formik.values.agreeToTerms}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="agreeToTerms"
                                  >
                                    <span>I agree to terms &amp; Policy.</span>
                                  </label>
                                  {formik.touched.agreeToTerms &&
                                  formik.errors.agreeToTerms ? (
                                    <div className="text-danger">
                                      {formik.errors.agreeToTerms}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-3">
                              {loading ? (
                                <button
                                  type="submit"
                                  className="btn btn-fill-out btn-block"
                                  name="button"
                                  disabled
                                >
                                  Processing...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-fill-out btn-block"
                                  name="register"
                                >
                                  Register
                                </button>
                              )}
                            </div>
                            {/* <div className="text-center mb-2">OR</div>
                          <div className="d-flex justify-content-center">
                            <GoogleOAuthProvider
                              clientId={process.env.REACT_APP_CLIENT_ID}
                            >
                              <GoogleLogin
                                onSuccess={(credentialResponse) => {
                                  handleGoogleLoginSuccess(credentialResponse);
                                }}
                                logo_alignment={"center"}
                                type={"standard"}
                                onError={() => {
                                  console.log("Login Failed");
                                }}
                              />
                            </GoogleOAuthProvider>
                          </div> */}
                          </form>
                          {/* <div className="different_login">
                          <span> </span>
                        </div> */}
                          <div className="form-note text-center">
                            Already have an account?{" "}
                            <Link
                              onClick={() => {
                                dispatch(signUpHide());
                                dispatch(loginShow());
                              }}
                            >
                              Log in
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupModal;
