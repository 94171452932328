import React, { useState } from "react";

function ProductList({list}) {
    const[modalData,setModalData]=useState()
  return (
    <>
      <div className="main_content mt-3">
        {/* START SECTION BLOG */}
        <div className="section">
          <div className="container">
            <div className="row align-items-center mb-4 pb-1">
              <div className="col-12">
                <div className="product_header">
                  <div className="product_header_left">
                    <div className="widget">
                      <div className="search_form">
                        <form action method="get">
                          <input
                            required
                            name="q"
                            className="form-control"
                            placeholder="Search..."
                            type="text"
                          />
                          <button
                            type="submit"
                            title="Search"
                            className="btn icon_search"
                          >
                            <i className="ion-ios-search-strong" />
                          </button>
                        </form>
                      </div>
                      {/* <a href="">Back to All Brands</a> */}
                    </div>
                  </div>
                  <div className="product_header_right">
                    Amount : <span className="price">₹ {list?.amount} </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
                {
                   list?.data && list?.data?.length!=0 ? 
<>
{
    list?.data?.map((item)=>{
        return(
<div className="col-lg-3 col-md-4 col-6">
                    <div className="product">
                      {/* {'{'}% if voucher.amount|brand_enabled:product.id == False %{'}'} */}
                     {item?.in_stock==true ?<></> :<span className="pr_flash">Out of Stock</span>} 
                      <div className="product_img" style={{ height: "163px" }}>
                        <a onClick="alert('Brand is out of stock or not available this time, please select other brand.')">
                          <img
                            src={item?.images_json?.mobile}
                            className="col-12"
                            style={{ width: "100% !important" }}
                            alt="{{ product.product_name }}-Image"
                          />
                        </a>
                        <div className="product_action_box">
                          <ul className="list_none ">
                            <li className=" btn btn-fill-out bg-white rounded-0">
                              {item?.in_stock==true?   <a
                               data-toggle="modal" data-target="#select-brand-modal"
                                className="prodcut-details"
                                onClick={()=>setModalData(item)}
                              >
                                Pick This Brand
                              </a>:   <a
                              
                              
                                className="prodcut-details"
                                onClick={()=>alert('Brand is out of stock or not available this time, please select other brand.')}
                              >
                                Pick This Brand
                              </a>}
                            
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="product_info">
                        <h6 className="product_title">
                          <a
                            onClick="alert('Brand is out of stock or not available this time, please select other brand.')"
                            title="{{ product.product_name }}"
                          >
                           { item?.product_name}
                          </a>
                        </h6>
                        <div className="product_price text-center">
                          <small>
                            <span className="price"> {item?.name} </span>
                            {/* <del>$99.00</del> */}
                          </small>
                        </div>
                      </div>
                      
    
                
                    </div>
                    
                  </div>
        )
    })
}

</>
                   :
                         <div className="row text-center align-items-center">
                    <div className="col-lg-12">
                      No Data Found <br />
                      <p className="col-lg-12 my-2 px-5 py-2 d-flex justify-content-between">
                        <a href="https://api.whatsapp.com/send?phone=917977276921&text=Hello Credence Rewards admin, I Have a Business" onclick="return confirm('Go to whatsapp')">
                          <i className="ion-social-whatsapp-outline" />
                          <span>Chat Directly</span>
                        </a>
                        <a href="mailto:support@credencerewards.com?subject=Issue related to order on Credence Rewards.com">
                          <i className="linearicons-envelope-open" />
                          <span>Write Us </span>
                        </a>
                      </p>
                    </div>
                  </div> 
                }
             
             
            </div>
            {/* END SECTION BLOG */}
          </div>
        </div>
      </div>

      <div
        className="modal fade subscribe_popup "
        data-backdrop="static"
        data-keyboard="false"
        id="select-brand-modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="select-brand-modal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
        
              >
                <span aria-hidden="true">
                  <i className="ion-ios-close-empty" />
                </span>
              </button>
              <div className="row g-0">
                <div className="col-sm-5 border">
                  <img
                    className="background_bg product_img product_bg m-4"
                    src={modalData?.images_json?.small}
                    style={{
                      height: 183,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                  <div className="product_info text-center ">
                    <h6 className="product_title" >{modalData?.product_name}</h6>
                    <div className="product_price text-center py-2">
                      <small>
                        <span className="price" id="product_brand_name" >{modalData?.name}</span>
                      </small>
                    </div>
                  </div>
                </div>
                <div className="col-sm-7">
                  <div className="popup_content">
                    <div className="popup-text">
                      <p>
                        Fill details to redeem your voucher.{" "}
                        <span style={{ float: "right" }}>
                          {" "}
                          Amount :{" "}
                          <span className="price">
                            ₹ {list?.amount}
                            
                          </span>
                        </span>
                      </p>
                    </div>
                    <form
                      
                      method="post"
                      id="redeem-form"
                    >
                      <div className="error-messages error text-center" />
                      
                      <div className="form-group mb-3">
                        <input
                          className="form-control rounded-0"
                          maxLength={50}
                          minLength={4}
                          name="name"
                        
                          placeholder="Name"
                          required=""
                          id="name"
                          autoComplete="off"
                          type="text"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <input
                          type="hidden"
                          defaultValue=""
                          name="provider_id"
                          id="cr_provider_id"
                        />
                        <input
                          className="form-control"
                          maxLength={60}
                          name="email"
                          placeholder="Email"
                          id="email"
                          autoComplete="off"
                          type="text"
                          required=""
                        />
                      </div>
                      <div className="form-group mb-3">
                        <input
                          type="hidden"
                          defaultValue="{{ voucher.credence_code }}"
                          name="credence_code"
                        />
                        <input
                          className="form-control"
                          maxLength={10}
                          minLength={10}
                          pattern="\d{10}"
                          name="mobile_no"
                        
                          required=""
                          placeholder="Indian Mobile Number"
                          id="mobile_no"
                          autoComplete="off"
                          type="number"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <button
                          className="btn btn-fill-line btn-block text-uppercase rounded-0 "
                          id="redeem-cr-code-btn"
                          title="Redeem"
                          type="submit"
                        >
                          Redeem
                        </button>
                      </div>
                    </form>
                    <div className="chek-form">
                      <div className="custome-checkbox">
                        <p className="text-muted">
                          <small>
                            Disclaimer: Your Email and Mobile No. will be used
                            to share the brand voucher code.
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-4 border">
                  <div
                    id="accordion"
                    className="accordion accordion_style1 mx-4 my-2"
                  >
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h6 className="mb-0">
                          {" "}
                          <a
                            className="collapsed"
                            data-bs-toggle="collapse"
                            href="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Description
                          </a>
                        </h6>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <p id="product-description">{modalData?.desc}</p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingEight">
                        <h6 className="mb-0">
                          {" "}
                          <a
                            className="collapsed"
                            data-bs-toggle="collapse"
                            href="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                          >
                            Validity / Expiry
                          </a>{" "}
                        </h6>
                      </div>
                      <div
                        id="collapseEight"
                        className="collapse"
                        aria-labelledby="headingEight"
                        data-bs-parent="#accordion2"
                      >
                        <div className="card-body">
                          <p id="product-validity">{modalData?.expiry}</p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h6 className="mb-0">
                          {" "}
                          <a
                            className="collapsed"
                            data-bs-toggle="collapse"
                            href="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Terms and Conditions
                          </a>
                        </h6>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordion"
                      >
                        <div className="card-body">
                          <small>
                            <p id="product-terms-conditions">{modalData?.t_c_content}</p>
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductList;
