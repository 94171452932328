import React from "react";
import { useNavigate, useParams } from "react-router";

function SectionBanner() {
  const navigate = useNavigate();
  return (
    <>
      <>
        {/* START SECTION BANNER */}
        {/* <div className="">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="single_banner"
                  onClick={() =>
                    window.open("https://redeem.credencerewards.com/")
                  }
                  style={{ cursor: "pointer" }}
                > */}
        {/* <img
                    src="assets/index-banner/Magic.png"
                    className=""
                    alt="credence code"
                  /> */}
        {/* <div className="single_banner_info text-center">
              <h3 className="single_bn_title1 text-center">
                <p className="text-light m-0 p-0"> Enter Your Credence Code</p>
              </h3>
              <h5 className="single_bn_title text-white text-center">
                {" "}
                Claim Your Brand Delight!
              </h5>
              <a
                href="accounts/login/index.html"
                className="btn btn-fill-out rounded-0"
              >
                Redeem Now
              </a>
            </div> */}
        {/* </div>
              </div> */}
        {/* <div className="col-md-6">
                <div
                  className="single_banner"
                  onClick={() => navigate("/b-2-b/corporate-gifting")}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src="assets/index-banner/Business.png"
                    alt="Business img"
                  /> */}
        {/* <div className="single_banner_info text-center">
              <h3 className="single_bn_title text-white">
                Special offer for Business
              </h3>
              <h4 className="single_bn_title1 text-white" />
              <a
                href="b-2-b/business-to-business.html"
                className="btn btn-fill-out px-2 mt-5  rounded-0"
              >
                Discount for B2B
                <i className="flaticon-arrow-right" />
              </a>
            </div> */}
        {/* </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* END SECTION BANNER */}
      </>
      <div
        className=" d-flex  justify-content-center align-items-center  gap-5 mx-auto my-md-4"
        // style={{  }}
      >
        <span
          className="single_banner"
          onClick={() => window.open("https://redeem.credencerewards.com/")}
          style={{ cursor: "pointer", width: "35%", padding: "15px" }}
        >
          <img
            src="assets/index-banner/Magic.png"
            className=""
            alt="credence code"
          />
        </span>
        <span
          className="single_banner"
          style={{ cursor: "pointer", width: "35%", padding: "15px" }}
        >
          <img
            // style={{ width: "40%" }}
            src="assets/index-banner/Business.png"
            alt="Business img"
          />
        </span>
      </div>
    </>
  );
}

export default SectionBanner;
