import React from "react";
import ReactOwlCarousel from "react-owl-carousel";

function Testimonial() {
  return (
    <>
      <div className="py-5 bg_redon">
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <div className="heading_s1 text-center">
                <h2>Our Client Say!</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              {/* <div
          className="testimonial_wrap testimonial_style1 carousel_slider owl-carousel owl-theme nav_style2"
          data-nav="true"
          data-dots="false"
          data-center="true"
          data-loop="true"
          data-autoplay="true"
          data-items={1}
        >
        
        </div> */}
              <ReactOwlCarousel
                className="testimonial_wrap testimonial_style1 carousel_slider owl-carousel owl-theme nav_style2"
                items={1}
                margin={10}
                nav
                dots={false}
                autoplay={true}
              >
                <div className="testimonial_box">
                  <div className="testimonial_desc">
                    <p>
                      What truly sets your website apart is the personalized
                      touch it offers. The option to add custom messages and
                      select from a variety of stylish designs made my gift
                      cards feel thoughtful and unique. The recipients were
                      thrilled to receive such personalized gifts, and it added
                      a special touch to their celebrations.
                    </p>
                  </div>
                  <div className="author_wrap">
                    <div className="author_img">
                      <img src="assets/imgs/user_img1.jpg" alt="user_img1" />
                    </div>
                    <div className="author_name">
                      <h6>Manisha Vapte</h6>
                      <span />
                    </div>
                  </div>
                </div>
                <div className="testimonial_box">
                  <div className="testimonial_desc">
                    <p>
                      I must also commend your customer support team for their
                      exceptional assistance. I had a few queries regarding the
                      redemption process, and they were quick to respond,
                      providing clear and helpful guidance. It's refreshing to
                      receive such responsive and friendly customer service in
                      today's digital age.
                    </p>
                  </div>
                  <div className="author_wrap">
                    <div className="author_img">
                      <img src="assets/imgs/user_img2.jpg" alt="user_img2" />
                    </div>
                    <div className="author_name">
                      <h6>Mukhtar Ahmad</h6>
                      <span />
                    </div>
                  </div>
                </div>
                <div className="testimonial_box">
                  <div className="testimonial_desc">
                    <p>
                      I am writing to express my utmost gratitude for the
                      incredible service provided by your website. As a user, I
                      wanted to share my experience and how it has positively
                      impacted my gift-giving endeavors.
                    </p>
                  </div>
                  <div className="author_wrap">
                    <div className="author_img">
                      <img src="assets/imgs/user_img3.jpg" alt="user_img3" />
                    </div>
                    <div className="author_name">
                      <h6>Sumiti Agarwal</h6>
                      <span />
                    </div>
                  </div>
                </div>
                <div className="testimonial_box">
                  <div className="testimonial_desc">
                  <p>Thanks to your website, my gift-giving experiences have become stress-free and
                                    joyous occasions. I no longer have to worry about last-minute gift ideas or
                                    dealing with the hassles of physical gift shopping. Your platform has truly
                                    simplified the process, making it an absolute pleasure to share the joy of
                                    gift-giving with my loved ones.</p>
                  </div>
                  <div className="author_wrap">
                    <div className="author_img">
                      <img src="assets/imgs/user_img4.jpg" alt="user_img4" />
                    </div>
                    <div className="author_name">
                      <h6>Abhinav Sharma</h6>
                      <span />
                    </div>
                  </div>
                </div>
              </ReactOwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;
