import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  checkCouponCode,
  createOrder,
  getLifestyleStock,
  getProductByCatID,
  getProductDetail,
} from "../services/Apiservices";
import { BASE_URL, restrictAlpha } from "../constants";
import ReactOwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import SubscribeSection from "./Homepage/SubscribeSection";
import showNotification from "../services/NotificationService";
import { loginShow } from "../redux/reducers/modalSlice";
import MobileNumberModal from "../AuthComponents/MobileNumberModal";
import { setProductId } from "../redux/reducers/productIdSlice";
import axios from "axios";

function LifeStyleCheckout() {
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const product = useSelector((state) => state?.productId?.id);

  const user = useSelector((state) => state?.user?.user);
  const { customId } = useParams();

  const navigate = useNavigate();

  const [productDetail, setProductDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [customPrice, setCustomPrice] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [isPriceEdit, setISPriceEdit] = useState(true);
  const [quantMessage, setQuantMessage] = useState("");
  const [demoMessage, setDemoMessage] = useState("");
  const [isCuponSent, setIsCuponSent] = useState(false);
  const [disable, setDisable] = useState(false);

  const dispatch = useDispatch();

  const [denominatioQuantity, setDenominatioQuantity] = useState(1);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [stockQuantities, setStockQuantities] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scroll effect
    });
    getCategorizedData();
    getRelatedData();
    getStockData();
  }, [customId]);

  const getStockData = async () => {
    try {
      let response = 
      
      customId == "lifestyle-offline-store-voucher"
      ? await axios.get(
          "https://api.credencerewards.com/lifestyle/vouchers-stock"
        )
      : await axios.get(
        "https://api.credencerewards.com/shoppers-stop/vouchers-stock"
      )
    
      if (response?.status == 201) {
        setStockData(response?.data);
      }
    } catch (error) {}
  };
  const getCategorizedData = async () => {
    setLoading(true);

    try {
      let response = await getProductDetail(customId);
      if (response?.status == 200) {
        getStockData();
        setProductDetail(response?.data?.data);
        setCustomPrice(response?.data?.data?.checkout?.max);
        setDenominatioQuantity(response?.data?.data?.checkout?.quantity);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      setProductDetail("");
    }
  };
  const getRelatedData = async () => {
    try {
      let response = await getProductByCatID(43);
      if (response?.status == 200) {
        setProducts(response?.data?.data);
      }
    } catch (error) {
      setProducts([]);
    }
  };

  const applyCouponCode = async () => {
    try {
      let response = await checkCouponCode(couponCode, productDetail?.id);
      if (response?.status == 200) {
        setIsCuponSent(true);
        showNotification("success", response?.data?.message);
      }
    } catch (error) {}
  };
  const handleInputFocus = (e) => {
    // Clear any previous error messages
    // Perform validation checks when an input field is focused
    switch (e.target.id) {
      case "denominatioNumber":
        setDemoMessage("");
        if (productDetail?.product_type == 2) {
          if (
            customPrice < productDetail?.checkout?.min ||
            customPrice > productDetail?.checkout?.max
          ) {
            setDemoMessage(`Entered Denomination must be in range from Min 
           ${productDetail?.checkout?.min} 
           and Max
           ${productDetail?.checkout?.max}`);
          }
        } else {
          if (!productDetail?.checkout?.denominations?.includes(customPrice)) {
            setDemoMessage(
              "Entered Denomination must be from given denominations "
            );
          } else {
            setDemoMessage("");
          }
        }
        // if (denominatioNumber === '') {
        //   alert('Please enter a denomination.');
        // }
        break;
      case "denominatioQuantity":
        setQuantMessage("");
        if (denominatioQuantity === "") {
          setQuantMessage("Enter Quantity");
        } else if (denominatioQuantity == 0) {
          setQuantMessage("Invaliad quantity");
        } else if (denominatioQuantity > productDetail?.checkout?.quantity) {
          setQuantMessage(
            `Upto ${productDetail?.checkout?.quantity} Cards allowed in one order`
          );
        }

        break;
      default:
        break;
    }
  };
  const handleIncrese = () => {
    if (customPrice < productDetail?.checkout?.max) {
      setCustomPrice((previous) => previous + 10);
    }
  };
  const handleDecrease = () => {
    if (customPrice > productDetail?.checkout?.min) {
      setCustomPrice((previous) => previous - 10);
    }
  };
  const isQuantityValid = (quantity, maxAllowedQuantity) => {
    return quantity <= maxAllowedQuantity;
  };
  const calculateTotalQuantity = () => {
    return stockQuantities.reduce((total, stock) => total + stock.qty, 0);
  };

  const calculateTotalAmount = () => {
    return stockQuantities.reduce(
      (total, stock) => total + stock.qty * stock.type,
      0
    );
  };
  let amount = calculateTotalAmount() * (1 - stockData?.data?.discount / 100);

  const handleCreateOrder = async () => {
    if (isLoggedIn) {
      if (stockQuantities?.length !== 0) {
        if (user?.mobile_no) {
          setDisable(true);

          let body = {
            name: user?.full_name,
            email: user?.email,
            mobile_no: user?.mobile_no,
            items: stockQuantities,
          };

          try {
            let response =
              customId == "lifestyle-offline-store-voucher"
                ? await axios.post(
                    "https://api.credencerewards.com/lifestyle/create-transaction",
                    body
                  )
                : await axios.post(
                    "https://api.credencerewards.com/shoppers-stop/create-transaction",
                    body
                  );
            if (response?.status == 200) {
              setDisable(false);

              displayRazorpay(response?.data?.data);
            }
          } catch (error) {}
        } else {
          setShowMobileModal(true);
        }
      } else {
        showNotification("danger", "Please select a voucher");
      }
    } else {
      dispatch(loginShow());
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(data) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    // const result = await axios.post("http://localhost:5000/payment/orders");

    // if (!result) {
    //     alert("Server error. Are you online?");
    //     return;
    // }

    // const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.REACT_APP_SEC_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount,
      currency: "INR",
      name: "Credence Rewards",
      description: data?.desc,
      image: "../../../assets/CR Logo.png",
      order_id: data?.id,
      handler: async function (response) {
        navigate("/wallet/order-completed");
      },
      prefill: {
        name: data?.name,
        email: data?.email,
        contact: data?.mobile,
      },

      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handleNaviagte = (item) => {
    dispatch(setProductId(item?.id));

    navigate(`/${item?.url}`);

    //     if(isLoggedIn){
    // navigate(`/brands/${item?.id}/${item?.name}`)
    //     }else{
    //       dispatch(loginShow())
    //     }
  };

  const responsiveOptions = {
    0: {
      items: 1, // Display one item on mobile
    },
    768: {
      items: 4, // Display four items on desktop
    },
  };

  const handleQuantityChange = (id, newQuantity) => {
    if (newQuantity === 0 || newQuantity == "") {
      // If the new quantity is zero, remove the object from stockQuantities
      const updatedQuantities = stockQuantities.filter(
        (stock) => stock.type !== id
      );
      setStockQuantities(updatedQuantities);
    } else {
      // Check if the stock id exists in the current stockQuantities
      const stockIndex = stockQuantities.findIndex(
        (stock) => stock.type === id
      );

      if (stockIndex !== -1) {
        // If the stock exists, update its quantity
        const updatedQuantities = [...stockQuantities];
        updatedQuantities[stockIndex].qty = newQuantity;
        setStockQuantities(updatedQuantities);
      } else {
        // If the stock doesn't exist, add it to stockQuantities
        setStockQuantities((prevQuantities) => [
          ...prevQuantities,
          { type: id, qty: newQuantity },
        ]);
      }
    }
  };

  return (
    <>
      <div className="main_content mt-3">
        {/* START SECTION SHOP */}
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-5 mb-4 mb-md-0">
                <div className="product-image">
                  <div className="single_img p-4 bg-light">
                    <img
                      className="w-100 mb-4 bg-light"
                      src={BASE_URL + productDetail?.logo}
                      alt="blog_single_img1"
                    />
                  </div>
                  {productDetail?.if_available == true && stockData?.stock?.length!==0 ? (
                    <>
                      {stockData?.data?.discount > 0 && (
                        <div class="ribbon ribbon-top-right ">
                          <span class="bg-success">
                            <strong>{stockData?.data?.discount}% OFF</strong>
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <span className="pr_flash">Out of Stock</span>
                  )}

                  <ul className="product-meta">
                    <div className="col-lg-12">
                      <div className="toggle_info">
                        <span>
                          <i className="fas fa-tag" />
                          Have a coupon?{" "}
                          <a
                            href="#coupon"
                            data-bs-toggle="collapse"
                            className="collapsed text-primary"
                            aria-expanded="false"
                          >
                            Click here to enter your code
                          </a>
                        </span>
                      </div>
                      <div
                        className="panel-collapse coupon_form show"
                        id="coupon"
                      >
                        <div className="panel-body">
                          <div className="row text-center">
                            <p
                              className="small error text-center py-0 my-0"
                              id="discount-applied"
                            ></p>
                          </div>
                          <div className="coupon field_form input-group">
                            <input
                              type="text"
                              defaultValue=""
                              min={4}
                              max={20}
                              onChange={(e) => setCouponCode(e.target.value)}
                              name="check_coupon_code"
                              className="form-control"
                              placeholder="Enter Coupon Code.."
                              id="check_coupon_code"
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-fill-out btn-sm"
                                id="discount-code-btn"
                                type="button"
                                onClick={applyCouponCode}
                              >
                                Apply Coupon
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                  <div className="product_share row text-center">
                    <ul className="social_icons border">
                      <li>
                        <span>Share:</span>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/sharer/sharer.php?u=https://www.credencerewards.com/brands/f06cba9b-55f1-4444-a734-90108493cdd4/crs-1/Amazon Pay E-Gift Card"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="ion-social-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/intent/tweet?url=https://www.credencerewards.com/brands/f06cba9b-55f1-4444-a734-90108493cdd4/crs-1/amazon-pay-e-gift-card"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="ion-social-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wa.me/?text=https://www.credencerewards.com/brands/f06cba9b-55f1-4444-a734-90108493cdd4/crs-1/amazon-pay-e-gift-card"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="ion-social-whatsapp" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/?url=https://www.credencerewards.com/brands/f06cba9b-55f1-4444-a734-90108493cdd4/crs-1/amazon-pay-e-gift-card"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="ion-social-instagram-outline" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 ">
                <div className="pr_detail mx-3">
                  <form
                    className="card-form"
                    action=""
                    method="post"
                    id="checkoutForm"
                    noValidate="novalidate"
                  >
                    <div className="product_description">
                      <h4 className="product_title">
                        <span>{productDetail?.product_name}</span>{" "}
                        <small className="text-muted">
                          ({productDetail?.name})
                        </small>
                      </h4>
                      <hr />
                      <div className="product_sort_info ">
                        <ul>
                          <li>
                            <i className="linearicons-shield-check" />
                            Expiry: {productDetail?.expiry}
                          </li>
                          <li>
                            <i className="linearicons-sync" /> Instant Delivery
                            Via Email.
                          </li>
                          <li>
                            <i className="linearicons-bag-dollar" /> Secure
                            Payment Gateway Available.
                          </li>
                          <li>
                            <i className="linearicons-hourglass" /> No Hidden
                            Charges.
                          </li>
                          <li>
                            <i className="linearicons-hourglass" /> No Hidden
                            Charges.
                          </li>
                          {stockData?.data?.discount > 0 && (
                            <li>
                              <i class="linearicons-linearicons"></i>{" "}
                              <span class="mr-3">
                                Discount - {stockData?.data?.discount}% off.
                              </span>
                              <div class="text-success">
                                <small>(Will be applied on Payment.)</small>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                      {
                        stockData?.stock?.length!==0 &&   <div className="pr_switch_wrap">
                        <div className="switch_lable text-center">
                          Choose Denomination
                        </div>
                        <br />
                        <div className="product_size_switch row d-flex justify-content-center">
                          {stockData?.stock?.map((item, index) => {
                            return (
                              <>
                                <div className="d-flex">
                                  <a
                                    key={index}
                                    data-testid="brandValueDenom0"
                                    className="btn btn-fill-out m-4 font-weight-bold p-3 col-2 BrandValue_container__98UnK"
                                  >
                                    {item?.amount}
                                  </a>
                                  <div className="cart-product-quantity text-center">
                                    <label
                                      htmlFor="denominatioQuantity"
                                      className="small"
                                    >
                                      <small>Quantity</small>
                                    </label>
                                    <div className="quantity" >
                                      <input
                                        defaultValue={0}
                                        min={0}
                                        max={item?.vouchers}
                                        type="number"
                                        className="qty "
                                        onChange={(e) =>
                                          handleQuantityChange(
                                            item?.amount,
                                            e.target.value
                                              ? parseInt(e.target.value)
                                              : ""
                                          )
                                        }
                                        style={{width:"120px"}}
                                      />
                                      {isQuantityValid(
                                        stockQuantities?.length != 0
                                          ? stockQuantities[index]?.qty
                                          : 0,
                                        item?.vouchers
                                      ) ? (
                                        <></>
                                      ) : (
                                        stockQuantities[index]?.type ==
                                          item?.amount && (
                                          <p className="text-danger ">
                                            {" "}
                                            (Exceeds limit)
                                          </p>
                                        )
                                      )}
                                      {/* <input type="button" value="+" class="plus" onclick="updateInput(10)"> */}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      }
                    
                    </div>
                    {
                     stockData?.stock?.length!==0 &&  <div className="cart_extra">
                     <div className="cart_btn pull-right">
                       {disable ? (
                         <button
                           className="btn btn-fill-line"
                           type="button"
                           disabled
                         >
                           <i className="icon-rocket" />{" "}
                           <span
                             className="Button_button-label__yl844 button-label "
                             id="proceed-to-pay-btn"
                           >
                             <b>Processing Payment...</b>
                           </span>
                         </button>
                       ) : (
                         <button
                           className="btn btn-fill-line"
                           type="button"
                           onClick={handleCreateOrder}
                         >
                           <i className="icon-rocket" />{" "}
                           <span
                             className="Button_button-label__yl844 button-label "
                             id="proceed-to-pay-btn"
                           >
                             <b>Continue to pay ₹ {amount.toFixed(2)}</b>
                           </span>
                         </button>
                       )}
                     </div>
                   </div>
                    }
                   
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="large_divider clearfix" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="tab-style3">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="Description-tab"
                        data-bs-toggle="tab"
                        href="#Description"
                        role="tab"
                        aria-controls="Description"
                        aria-selected="true"
                      >
                        Description
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="Additional-info-tab"
                        data-bs-toggle="tab"
                        href="#Additional-info"
                        role="tab"
                        aria-controls="Additional-info"
                        aria-selected="false"
                      >
                        Terms and Conditions
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="Reviews-tab"
                        data-bs-toggle="tab"
                        href="#Reviews"
                        role="tab"
                        aria-controls="Reviews"
                        aria-selected="false"
                      >
                        How to redeem
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content shop_info_tab">
                    <div
                      className="tab-pane fade active show"
                      id="Description"
                      role="tabpanel"
                      aria-labelledby="Description-tab"
                    >
                      <p dangerouslySetInnerHTML={{__html: productDetail?.desc}}></p>
                      
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Additional-info"
                      role="tabpanel"
                      aria-labelledby="Additional-info-tab"
                    >
                      <p dangerouslySetInnerHTML={{__html:productDetail?.t_c_content}}></p>
                      
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Reviews"
                      role="tabpanel"
                      aria-labelledby="Reviews-tab"
                    >
                      <p></p>
                      <ul>
                        <li>
                          <div className="">
                            {productDetail?.how_to_redeem
                              ? productDetail?.how_to_redeem
                              : ` Go to Selected Barnd's Website and select the items you
                        want to purchase. When you are ready to checkout, click
                        ""Proceed To Pay"".`}
                          </div>
                        </li>
                        <li>
                          <div className="">
                            Select the ""Pay By Gift Card"" option.
                          </div>
                        </li>
                        <li>
                          <div className="">
                            Enter your 16-digit gift card number and PIN
                            number(if needed).
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="small_divider" />
                <div className="divider" />
                <div className="medium_divider" />
              </div>
            </div>
            {/* START SECTION SHOP */}
            <div className="row">
              <div className="col-md-12">
                {products?.length !== 0 && (
                  <ReactOwlCarousel
                    className="testimonial_wrap testimonial_style1 carousel_slider owl-carousel owl-theme nav_style2"
                    responsive={responsiveOptions}
                    margin={10}
                    dots={true}
                    autoplay={true}
                  >
                    {products?.length !== 0 ? (
                      <>
                        {products?.slice(0, 8)?.map((item, index) => {
                          return (
                            <div className="product" key={index}>
                              <div
                                className="product_img "
                                style={{ height: "auto" }}
                              >
                                {item?.if_available == true ? (
                                  <>
                                    {item?.discount > 0 && (
                                      <div class="ribbon ribbon-top-right ">
                                        <span class="bg-success">
                                          <strong>{item?.discount}% OFF</strong>
                                        </span>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <span className="pr_flash">Out of Stock</span>
                                )}
                                <a
                                  title="Amazon Pay E-Gift Card - API Reseller - Credence Rewards"
                                  href="#"
                                >
                                  <img
                                    src={BASE_URL + item?.logo}
                                    className="col-12"
                                    style={{ width: "100% !important" }}
                                    alt="Amazon Pay E-Gift Card - API Reseller - Credence Rewards-Image"
                                  />
                                </a>
                                <div className="product_action_box">
                                  <ul className="list_none ">
                                    <li
                                      className=" btn btn-fill-out bg-white rounded-0"
                                      onClick={() => handleNaviagte(item)}
                                    >
                                      <span title="Amazon Pay E-Gift Card - API Reseller - Credence Rewards">
                                        Pick This Brand
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="product_info">
                                <h6 className="product_title">
                                  <a
                                  onClick={() => handleNaviagte(item)}
                                    title="Amazon Pay E-Gift Card - API Reseller - Credence Rewards"
                                  >
                                    {item?.product_name}
                                  </a>
                                </h6>
                                <div className="product_price text-center">
                                  <small>
                                    Redeem Upto
                                    <span className="price">
                                      ₹ {item?.price_json?.max}
                                    </span>
                                    {/* <del>$99.00</del> */}
                                  </small>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>No Products found for this category</>
                    )}
                  </ReactOwlCarousel>
                )}
              </div>
            </div>
            {/* END SECTION SHOP */}
          </div>
        </div>
        {/* END SECTION SHOP */}
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
        <SubscribeSection />
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
      </div>

      {loading && (
        <div className="preloader">
          <div className="lds-ellipsis">
            <span />
            <span />
            <span />
          </div>
        </div>
      )}
      {showMobileModal && (
        <MobileNumberModal
          showLogin={showMobileModal}
          hideLogin={setShowMobileModal}
        />
      )}
    </>
  );
}

export default LifeStyleCheckout;
