import React, { useEffect } from "react";
import SubscribeSection from "./Homepage/SubscribeSection";
import { Link, useNavigate } from "react-router-dom";

function OrderCompleted() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/wallet/my-orders');
    }, 4000); // 4000 milliseconds = 4 seconds

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, [navigate]);
  return (
    <div className="main_content ">
      {/* START SECTION SHOP */}
      <div className="section ">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-md-8 ">
              <div className="text-center order_complete">
                <i className="fas fa-check-circle" />
                <div className="heading_s1">
                  <h3>Your order is completed!</h3>
                </div>
                <p>
                  Thank you for your order! Your order is being processed and
                  will be completed within 2-6 hours.
                </p>
                <p>
                  {" "}
                  You will receive an email confirmation when your order is
                  completed.
                </p>
                <p>
                  You can check your orders under My Account{" "}
                  <Link to="/wallet/my-orders" style={{color:"red"}}>>My Orders</Link>
                </p>
                <p className="text-miuted py-2">
                  Note: If you are facing any issue, Feel free to contact us.
                  <br />
                </p>
                <Link to="/brands/all-brands" className="btn btn-fill-out">
                  Continue Shopping
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* END SECTION SHOP */}
      {/* START SECTION SUBSCRIBE NEWSLETTER */}
      <SubscribeSection />
      {/* START SECTION SUBSCRIBE NEWSLETTER */}
    </div>
  );
}

export default OrderCompleted;
