import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "../session";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/reducers/authSlice";
import LoginModalComponent from "../AuthComponents/LoginModalComponent";
import SignupModal from "../AuthComponents/SignupModal";
import { loginShow, signUpShow } from "../redux/reducers/modalSlice";

import { globalSearchApi } from "../services/Apiservices";
import "../Components/SearchBar/SearchBar.css";
import { SearchResultsList } from "../Components/SearchBar/SearchResultsList";

function Header() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const showLogin = useSelector((state) => state?.modalView?.showLogin);
  const showSignup = useSelector((state) => state?.modalView?.showSignup);
  const [items, setItems] = useState([]);
  const [hideSearch, setHideSearch] = useState(false);
  const [hideResultList, setHideResultList] = useState(false);
  const [searchData, setSearchData] = useState("");

  const ref = useRef(null);

  const navigate = useNavigate();
  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");

    if (confirmLogout) {
      dispatch(logout());
      navigate("/");
      localStorage.clear();
    }
  };

  const handleOnSearch = async (string, results) => {
    if (string.length != 0) {
      setHideResultList(true);
      try {
        let response = await globalSearchApi(string);
        if (response?.status == 200) {
          setItems(response?.data?.data);
          // navigate("/")
        }
      } catch (error) {}
    }

    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
  };

  const handleOnSelect = (item) => {
    setHideResultList(false);
    if (item?.type == "product") {
      navigate("/brands/all-brands", { state: item.name });
      setSearchData("");
    } else {
      navigate(item?.redirect);
      setSearchData("");
    }
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "flex", textAlign: "left" }}>{item.name}</span>
        <span style={{ fontSize: "10px" }}>{item?.type}</span>
      </>
    );
  };
  return (
    <>
      <header className="header_wrap fixed-top header_with_topbar">
        <div className="">
          <div className="container-2xl">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                  <ul className="contact_detail text-center text-lg-start">
                    {/* <li>
                      <Link className=" " to="/about-us">
                        About Us
                      </Link>
                    </li> */}
                    {/* <li>
                      <a href="mailto:support@credencerewards.com?subject=Issue related to order on CredenceRewards.com">
                        <i className="linearicons-envelope-open" />
                        <small>support@credencerewards.com</small>
                      </a>
                    </li> */}

                    {/* <li>
                      <a
                        href="https://api.whatsapp.com/send?phone=917977276921&text=Hello%20Credence%20Rewards%20admin,%20I%20have%20a%20Query"
                        onClick="return confirm('Go to whatsapp')"
                      >
                        <i className="ion-social-whatsapp-outline mx-0 px-0" />
                        <span>+91-7977276921</span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>

              {/* <div className="col-md-6">
                <div className="text-center text-md-end">
                  <ul className="header_list"> */}
              {/* <li>
                      <a
                        className="text-danger"
                        href="https://redeem.credencerewards.com/"
                      >
                        <i className="fas fa-wallet"></i>{" "}
                        <span>Redeem Credence Code</span>
                      </a>
                    </li> */}
              {/* {isLoggedIn ? ( */}
              {/* <>
                        <li>
                          <div class="dropdown show">
                            <a
                              class=" dropdown-toggle"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="ti-user" />
                              <span>My Account</span>
                            </a>

                            <div
                              class="dropdown-menu mt-4"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <Link
                                class="dropdown-item"
                                to="/accounts/my-account"
                              >
                                <i className="ti-user" />
                                My Profile
                              </Link>
                              <Link
                                class="dropdown-item"
                                to="/wallet/my-orders"
                              >
                                <i className="ti-shopping-cart-full" />
                                My Orders
                              </Link>

                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={handleLogout}
                              >
                                <i className="ti-lock" />
                                Logout
                              </a>
                            </div>
                          </div>
                        </li>
                      </> */}
              {/* ) : ( */}
              <>
                {/* <li>
                          <Link
                            className="text-danger"
                            onClick={() => dispatch(loginShow())}
                          >
                            <i className="ti-user" />
                            <span>Login/SignUp</span>
                          </Link>
                        </li> */}
                {/* <li>
                          <Link
                            className=""
                            onClick={() => dispatch(signUpShow())}
                          >
                            <i className="ion-person-add" />
                            <span></span>
                          </Link>
                        </li> */}
              </>
              {/* )} */}
              {/* </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="bottom_header dark_skin main_menu_uppercase">
          <div className=" ">
            <nav className="navbar navbar-expand-lg ">
              <Link
                className=" pt-0 mx-2"
                to="/"
                style={{ width: "fit-content" }}
              >
                <img
                  className="logo_light mx-auto"
                  src="../../../assets/images/logo_light.html"
                  alt="logo"
                  style={{ width: "200px" }}
                />
                <img
                  className="mx-auto"
                  src="../../../assets/Credence%20Rewards%20213_page-0001.jpg"
                  alt="logo"
                  style={{ width: "200px" }}
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-expanded="false"
                onClick={() => setHideSearch(!hideSearch)}
              >
                <span className="ion-android-menu" />
              </button>
              {hideSearch ? (
                <></>
              ) : (
                <div
                  className="search_form mobile mt-2 mb-2 mx-auto  "
                  style={{ width: "95%", zIndex: 2 }}
                >
                  {/* <ReactSearchAutocomplete
                    items={items}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    formatResult={formatResult}
                    inputDebounce={500}
                  /> */}
                  <div className="search-bar-container">
                    <div className="input-wrapper">
                      <i class="ionicons ion-search"></i>
                      <input
                        onChange={(e) => {
                          handleOnSearch(e.target.value);
                          setSearchData(e.target.value);
                        }}
                        value={searchData}
                      />
                    </div>
                    {items &&
                      items.length > 0 &&
                      hideResultList &&
                      searchData && (
                        <SearchResultsList
                          results={items}
                          handleOnSelect={handleOnSelect}
                        />
                      )}
                  </div>
                </div>
              )}
              <div
                className="search_form desktop mx-0"
                style={{ width: "20%" }}
              >
                {/* <ReactSearchAutocomplete
                  items={items}
                  onSearch={handleOnSearch}
                  onSelect={handleOnSelect}
                  formatResult={formatResult}
                /> */}
                <div className="search-bar-container">
                  <div className="input-wrapper">
                    <i class="ionicons ion-search"></i>
                    <input
                      onChange={(e) => {
                        handleOnSearch(e.target.value);
                        setSearchData(e.target.value);
                      }}
                      value={searchData}
                    />
                  </div>
                  {items &&
                    items.length > 0 &&
                    hideResultList &&
                    searchData && (
                      <SearchResultsList
                        results={items}
                        handleOnSelect={handleOnSelect}
                      />
                    )}
                </div>
              </div>
              <div
                className="collapse navbar-collapse justify-content-center "
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li style={{ fontSize: "12px", marginLeft: "12px" }}>
                    <Link
                      className="nav-link nav_item "
                      to="/brands/all-brands"
                    >
                      Brands
                    </Link>
                  </li>
                  <li style={{ fontSize: "12px", marginLeft: "12px" }}>
                    <Link
                      className="nav-link nav_item "
                      to="https://credencerewards.co.in"
                      target="
                      
                      _blank"
                    >
                      For Business
                    </Link>
                  </li>
                  <li
                    style={{ fontSize: "12px", marginLeft: "12px" }}
                    className="dropdown dropdown-mega-menu"
                  >
                    <a
                      className="dropdown-toggle nav-link "
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      Categories
                    </a>
                    <div className="dropdown-menu">
                      <ul className="mega-menu d-lg-flex">
                        <li className="mega-menu-col col-lg-3">
                          <ul>
                            <li className="dropdown-header">By Type</li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/1/category/Popular"
                              >
                                Most Popular
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/2/category/E-Commerce"
                              >
                                E-Commerce
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/3/category/Fashion&Style"
                              >
                                Fashion &amp; Lifestyle
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/4/category/Grocery"
                              >
                                Grocery
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/5/category/Home-Needs"
                              >
                                Home Needs
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/6/category/Home-Furnishings"
                              >
                                Home Furnishings
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/7/category/Travel"
                              >
                                Travel
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/8/category/Gaming"
                              >
                                Gaming
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/9/category/Entertainment"
                              >
                                Entertainment
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="mega-menu-col col-lg-3">
                          <ul>
                            <li className="dropdown-header">Occasions</li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/20/category/Mother`s Day"
                              >
                                Mother`s Day
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/21/category/Wedding"
                              >
                                Wedding
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/22/category/House Warming"
                              >
                                House Warming
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/23/category/New year"
                              >
                                New year
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/24/category/Birthday"
                              >
                                Birthday
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/25/category/Anniversary"
                              >
                                Anniversary
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/26/category/Experience"
                              >
                                Experience
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/27/category/Valentine's Day"
                              >
                                Valentine's Day
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/28/category/Diwali"
                              >
                                Diwali
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="mega-menu-col col-lg-3">
                          <ul>
                            <li className="dropdown-header">Reciept</li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/35/category/Men"
                              >
                                Men
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/36/category/Women"
                              >
                                Women
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/37/category/Teens"
                              >
                                Teens
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/38/category/Boys"
                              >
                                Boys
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/39/category/Boys"
                              >
                                Girls
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/40/category/Babies"
                              >
                                Babies
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/41/category/Oldies"
                              >
                                Oldies
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/42/category/Friends"
                              >
                                Friends
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="mega-menu-col col-lg-3">
                          <ul>
                            <li className="dropdown-header">Other</li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/34/category/Sister`s Day"
                              >
                                Sister`s Day
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/33/category/Easter"
                              >
                                Easter
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/32/category/Eid"
                              >
                                Eid
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/31/category/Christimas"
                              >
                                Christimas
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/15/category/Sportswear"
                              >
                                Sportswear &amp; Footwear
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/16/category/Subscriptions"
                              >
                                Subscriptions
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item nav-link nav_item"
                                to="/brand/19/category/Finance"
                              >
                                Finance &amp; Insurance
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      {/* <div className="d-lg-flex menu_banners row g-3 px-3">
                        <div className="col-sm-4">
                          <div className="header-banner">
                            <img
                              src="../../../assets/imgs/menu_banner1.jpg"
                              alt="menu_banner1"
                            />
                            <div className="banne_info">
                              <h6>10% Off</h6>
                              <h4>New Arrival</h4>
                                 <Link
                              to="/brand/2/category/E-Commerce"
>                                Shop now
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="header-banner">
                            <img
                              src="../../../assets/imgs/menu_banner2.jpg"
                              alt="menu_banner2"
                            />
                            <div className="banne_info">
                              <h6>15% Off</h6>
                              <h4>Men's Fashion</h4>
                              <Link
                              to="/brand/35/category/Men"
>                                Shop now
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="header-banner">
                            <img
                              src="../../../assets/imgs/menu_banner3.jpg"
                              alt="menu_banner3"
                            />
                            <div className="banne_info">
                              <h6>23% Off</h6>
                              <h4>Kids Fashion</h4>
                                  <Link
                              to="/brand/40/category/E-Commerce"
>                                Shop now
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </li>
                  <li
                    className="mr-3 "
                    style={{ fontSize: "12px", marginLeft: "12px" }}
                  >
                    <Link
                      className="nav-link nav_item  "
                      to="/brands/check-balance"
                    >
                      Check Balance
                    </Link>
                  </li>
                  <li
                    className=""
                    style={{ fontSize: "12px", marginLeft: "12px" }}
                  >
                    <a
                      className="text-danger nav-link nav_item"
                      href="https://redeem.credencerewards.com/"
                    >
                      <i className="fas fa-wallet"></i>{" "}
                      <span>Redeem Credence Code</span>
                    </a>
                  </li>
                  {isLoggedIn ? (
                    <>
                      <li
                        className="  nav-link nav_item"
                        style={{ fontSize: "13px", marginLeft: "24px" }}
                      >
                        <div class="dropdown show">
                          <a
                            class=" dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="ti-user" />
                            <span>My Account</span>
                          </a>

                          <div
                            class="dropdown-menu mt-4"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <Link
                              class="dropdown-item"
                              to="/accounts/my-account"
                            >
                              <i className="ti-user" />
                              My Profile
                            </Link>
                            <Link class="dropdown-item" to="/wallet/my-orders">
                              <i className="ti-shopping-cart-full" />
                              My Orders
                            </Link>

                            <a
                              class="dropdown-item"
                              href="#"
                              onClick={handleLogout}
                            >
                              <i className="ti-lock" />
                              Logout
                            </a>
                          </div>
                        </div>
                      </li>
                    </>
                  ) : (
                    <>
                      <li
                        className="nav-link nav_item"
                        style={{ fontSize: "13px", marginLeft: "24px" }}
                      >
                        <Link
                          className="text-danger"
                          onClick={() => dispatch(loginShow())}
                        >
                          <i className="ti-user" />
                          <span>Login/SignUp</span>
                        </Link>
                      </li>
                      {/* <li>
                          <Link
                            className=""
                            onClick={() => dispatch(signUpShow())}
                          >
                            <i className="ion-person-add" />
                            <span></span>
                          </Link>
                        </li> */}
                    </>
                  )}
                </ul>
              </div>
              {/* <ul className="navbar-nav attr-nav align-items-center">
                <li>
                  <a
                    href="javascript:void(0);"
                    className="nav-link search_trigger"
                  >
                    <i className="linearicons-magnifier" />
                  </a>
                  <div className="search_wrap">
                    <span className="close-search">
                      <i className="ion-ios-close-empty" />
                    </span>
                    <form
                      action="http://127.0.0.1:8000/brands/all-brands"
                      method="get"
                    >
                      <input
                        type="text"
                        placeholder="Search here . . . e.g amazon"
                        className="form-control"
                        id="search_input"
                        name="q"
                        aria-describedby="search"
                      />
                      <button type="submit" className="search_icon">
                        <i className="ion-ios-search-strong" />
                      </button>
                    </form>
                  </div>
                  <div className="search_overlay" />
                </li>
              </ul> */}
              {/* <ul className="header_list d-flex gap-1"> */}
              {/* <li>
                  <a
                    className="text-danger"
                    href="https://redeem.credencerewards.com/"
                  >
                    <i className="fas fa-wallet"></i>{" "}
                    <span>Redeem Credence Code</span>
                  </a>
                </li> */}
              {/* {isLoggedIn ? ( */}
              <>
                {/* <li>
                      <div class="dropdown show">
                        <a
                          class=" dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="ti-user" />
                          <span>My Account</span>
                        </a>

                        <div
                          class="dropdown-menu mt-4"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <Link class="dropdown-item" to="/accounts/my-account">
                            <i className="ti-user" />
                            My Profile
                          </Link>
                          <Link class="dropdown-item" to="/wallet/my-orders">
                            <i className="ti-shopping-cart-full" />
                            My Orders
                          </Link>

                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={handleLogout}
                          >
                            <i className="ti-lock" />
                            Logout
                          </a>
                        </div>
                      </div>
                    </li> */}
              </>
              {/* ) : ( */}
              <>
                {/* <li>
                      <Link
                        className="text-danger"
                        onClick={() => dispatch(loginShow())}
                      >
                        <i className="ti-user" />
                        <span>Login/SignUp</span>
                      </Link>
                    </li> */}
                {/* <li>
                          <Link
                            className=""
                            onClick={() => dispatch(signUpShow())}
                          >
                            <i className="ion-person-add" />
                            <span></span>
                          </Link>
                        </li> */}
              </>
              {/* )} */}
              {/* </ul> */}
            </nav>
          </div>
        </div>
      </header>
      {showLogin && <LoginModalComponent />}

      {showSignup && <SignupModal />}
    </>
  );
}

export default Header;
