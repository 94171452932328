// reducers/index.js
import { combineReducers } from 'redux';
import mobileNumberSlice from './mobileNumberSlice';
import authSlice from './authSlice';
import userSlice from './userSlice';
import modalSlice from './modalSlice';
import productIdSlice from './productIdSlice';

const rootReducer = combineReducers({
    mobileNumber: mobileNumberSlice,
    auth: authSlice,
    user: userSlice,
    modalView:modalSlice,
    productId:productIdSlice
    
    // Add other reducers here
  });

export default rootReducer;
