import React from "react";
import SectionBanner from "./SectionBanner";
import ShopSection from "./ShopSection";
import FeatureProduct from "./FeatureProduct";
import Testimonial from "./Testimonial";
import ShopAndSubsCribe from "./ShopAndSubsCribe";

function MainContent() {
  return (
    <div className="main_content">
      <ShopSection />
      {/* <SectionBanner /> */}
      <FeatureProduct />
      <Testimonial />
      <ShopAndSubsCribe />
    </div>
  );
}

export default MainContent;
