// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null, // Initial state with no user details
};

const productIdSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductId: (state, action) => {
      state.id = action.payload;
    },
  
  },
});

export const { setProductId } = productIdSlice.actions;
export default productIdSlice.reducer;
