import React from 'react'
import SubscribeSection from './SubscribeSection'

function ShopAndSubsCribe() {
  return (
    <>
    <>
  {/* START SECTION SHOP INFO */}
  {/* <div className="section pb_70">
    <div className="container">
      <div className="row g-0 border py-5 bg-light">
        <div className="col-lg-4">
          <div className="icon_box icon_box_style1">
            <div className="icon">
              <i className="flaticon-shipped" />
            </div>
            <div className="icon_box_content">
              <h5>Instant Delivery</h5>
              <p>
                We understand the importance of timely gifting, and that's why
                we offer instant delivery.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="icon_box icon_box_style1">
            <div className="icon">
              <i className="flaticon-money-back" />
            </div>
            <div className="icon_box_content">
              <h5>No Hidden Charges</h5>
              <p>
                Transparency is at the core of our values. So, there are no
                hidden charges or additional fees.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="icon_box icon_box_style1">
            <div className="icon">
              <i className="flaticon-support" />
            </div>
            <div className="icon_box_content">
              <h5>24/7 Support</h5>
              <p>
                We are available 24 X 7, for your service and help. We are
                serving 2 Cr plus users.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* END SECTION SHOP INFO */}
  {/* START SECTION SUBSCRIBE NEWSLETTER */}
 <SubscribeSection/>
  {/* START SECTION SUBSCRIBE NEWSLETTER */}
</>

    </>
  )
}

export default ShopAndSubsCribe