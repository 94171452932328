import React from "react";
import LazyLoad from "react-lazyload";
import { Tilt } from "react-tilt";
import { BASE_URL,defaultOptions } from "../constants";
import credenceCards from "../assets/CredenceCards.png";

const Gifcard = ({ item, handleNaviagte }) => {

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };
  
  return (
    <div>
      <div
        className="shadow"
        style={{
          width: "240px",
          height: "100%",
          borderRadius: "8px",
          border: "1.5px solid #ECECEC",
          margin: "10px",
          ...{
            "@media (max-width: 576px)": {
              width: "100%",
              height: "auto",
              margin: "5px 0",
            },
          },
        }}
      >
        <Tilt options={defaultOptions}>
          {item?.logo ? (
            <LazyLoad
              key={item?.id}
              once={true}
              placeholder={
                <img
                  src={credenceCards}
                  alt="Placeholder"
                  style={{
                    width: "100% !important",
                    height: "163px !important",
                    padding: "7px 8px",
                    cursor: "pointer",
                  }}
                />
              }
            >
              <img
                src={BASE_URL + item?.logo}
                alt="Image Alt"
                className="card-img-top"
                style={{
                  width: "100% !important",
                  height: "163px !important",
                  padding: "7px 8px",
                  cursor: "pointer",
                }}
              />
            </LazyLoad>
          ) : (
            <img
              src={credenceCards}
              className="col-12"
              style={{
                width: "100% !important",
                height: "163px !important",
                padding: "7px 8px",
                cursor: "pointer",
              }}
              onClick={() => {
                item?.if_available
                  ? handleNaviagte(item)
                  : alert(
                      "Brand is out of stock or not available this time, please select other brand."
                    );
              }}
              alt="Credence Rewards-card"
            />
          )}
        </Tilt>
        <div
          className=""
          style={{
            textAlign: "center",
            padding: "1px 8px",
            fontWeight: "600",
            color: "black",
            fontSize: "14.5px",
            cursor: "pointer",
          }}
          onClick={() => {
            item?.if_available
              ? handleNaviagte(item)
              : alert(
                  "Brand is out of stock or not available this time, please select other brand."
                );
          }}
        >
          {truncateText(item?.product_name, 30)}
        </div>
        <div
          className="d-flex justify-content-between align-items-center pt-3"
          style={{
            padding: "7px 8px",
          }}
        >
          {item?.if_available == true ? (
            <>
              {item?.discount > 0 ? (
                <div
                  style={{
                    paddingLeft: "3px",
                    borderLeft: "3px solid #FF324D",
                    color: "#FF324D",
                    fontSize: "18px",
                    borderRadius: "1px",
                    fontWeight: "700",
                  }}
                >
                  {item?.discount}%
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Discount
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    paddingLeft: "3px",
                    borderLeft: "3px solid #FF324D",
                    color: "#000",
                    fontSize: "18px",
                    borderRadius: "1px",
                    fontWeight: "700",
                  }}
                >
                  Instant
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Delivery
                  </div>
                </div>
              )}
            </>
          ) : (
            <span>Out of Stock</span>
          )}

          <div
            style={{
              backgroundColor: "#000",
              color: "white",
              padding: "5px ",
              paddingLeft: "10px",
              paddingRight: "10px",
              fontSize: "12px",
              fontWeight: "700",
              cursor: "pointer",
              borderRadius: "8px",
            }}
            onClick={() => {
              item?.if_available
                ? handleNaviagte(item)
                : alert(
                    "Brand is out of stock or not available this time, please select other brand."
                  );
            }}
          >
            BUY NOW
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gifcard;
