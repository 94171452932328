import React, { useEffect } from 'react'

function PrivacyPolicy() {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This provides a smooth scroll effect
          });
    },[])
  return (
    <>
    <div className="section">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="term_conditions">
          <h1 className="text-center mt-3">Privacy Policy</h1>
          <div className="page-content-block">
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CredenceRewards.com Ltd.
              (CredenceRewards.com”) is committed towards securing the privacy
              of information of those who use our services. We wrote this policy
              to help you understand what information we collect, how we use it
              and what choices you have about it. We welcome your questions and
              comments on this policy.
            </p>
            <p>
              Please read this Privacy Policy closely. By visiting our website
              or using our mobile application and/or providing your personal
              information to CredenceRewards.com, you agree that
              CredenceRewards.com may collect, use, and disclose your personal
              information as described in this Privacy Policy. We routinely
              update this Privacy Policy as our products and offerings change,
              so check back regularly for updates or changes. By using
              CredenceRewards.com web and/or mobile applications (together the
              "Applications"), you are accepting the practices described in this
              Privacy Policy.
            </p>
            <h2>How and What Personal Information Do We Gather?</h2>
            <p>
              CredenceRewards.com collects information when you use the
              Applications. For example, we collect data when you purchase or
              receive an eGift Card or Physical Gift Card (collectively "Gift
              Card") or we collect data when you contact our Customer Support.
              We only collect as much information as we need for specific and
              identified purposes.
            </p>
            <ul>
              <li>
                <strong>Personal Information:</strong> When you purchase or
                receive a Gift Card, you will be asked to provide certain
                personal information, such as your name, email address, phone
                number, or similar information. This information is collected to
                enable CredenceRewards.com to process your order.
                <br />
                <br />
                We do not store your credit card number, this information is
                directly passed to our payment gateway provider for the purpose
                of processing your payment.
                <br />
                <br />
                We may also collect personally identifiable information
                automatically when you use the Applications with Facebook or
                Google. Some of this information we receive are needed to set up
                your account and send your gift. You can choose not to use
                Facebook or Google with the Applications, and you can also
                choose not to provide certain personally identifiable
                information, but then you might not be able to take advantage of
                the services provided by the Applications.
              </li>
              <li>
                <strong>Cookies Information:</strong> When you visit the
                Applications, we may send one or more cookies (a small text file
                containing a string of alphanumeric characters) to your
                computer. CredenceRewards.com uses both session cookies and
                persistent cookies. A persistent cookie remains after you close
                your browser and may be used by your browser on subsequent
                visits to the Sites. These persistent cookies can be removed,
                but each web browser is a little different. Please review your
                browser's "Help" file to learn the correct way to modify your
                cookies setup. We do not link the information we store in
                cookies to any personally identifiable information you submit
                while on our site.
              </li>
              <li>
                <strong>Automatically Collected Information:</strong> When you
                use the Applications or open one of our emails, we automatically
                record certain data using technology, including "clear gifs" or
                "web beacons," cookies (discussed above), IP addresses, and
                unique "device IDs" (similar to IP addresses). "Automatically
                Collected" data includes web browser type, the website that
                referred you to us, the web pages you viewed on the
                Applications, and the dates and times that you visited the
                Applications.
              </li>
              <li>
                <strong>Client Provided Information:</strong> When
                CredenceRewards.com manages promotions or other services for a
                Client (a company or organization to which CredenceRewards.com
                provides business services) some personal information is also
                shared with CredenceRewards.com in order to process the Gift
                Card creation and redemption activity on behalf of that Client.
                The use of personal information shared with us by our clients is
                also subject to the conditions mentioned in this privacy policy.
              </li>
            </ul>
            <h2>Receivers of Gift Cards</h2>
            <p>
              When you choose to use our services to send a Gift Card to anyone,
              you represent that you have their consent to provide us their
              name, email address and/or mobile number. We will automatically
              send the receiver of Gift Card an email or mobile SMS informing
              them of the received Gift Card. Unless we are authorized by your
              friend, we will only use your friend’s name, email address and/or
              mobile number for the purposes of sending this email and reminder
              emails for using the Gift Cards and maintaining an activity log of
              it.
            </p>
            <h2>How We Use the Personal Information?</h2>
            <p>
              We use personally identifiable information to respond to your
              requests, sell you a Gift Card, improve your purchasing
              experience, customize your future purchases, improve our
              Applications, improve our marketing and promotional efforts, and
              to communicate with you for order verification, customer service
              purposes, send you reminders for use of Gift Cards, notices,
              administrative messages, and requested information, including such
              messages on behalf of our Clients and occasionally send you
              marketing information about CredenceRewards.com and our business
              partners and their products and services. We also use such
              information to troubleshoot problems, resolve disputes, accomplish
              administrative tasks, enforce our agreements with you, including
              our Terms and Conditions and this Privacy Policy, comply with
              applicable law and cooperate with law enforcement activities.
            </p>
            <p>
              The processing and use of the data you provide to us is done in
              the United Arab Emirates and United States of America. By using
              the Applications and providing information to CredenceRewards.com,
              you are expressly consenting to this.
            </p>
            <h2>Third-Party Software/Service Providers</h2>
            <p>
              We use UXCam, which is an analytics solution. UXCam may record:
              Screens visited, Interaction patterns (such as screen actions,
              gestures: taps, scrolls), and Device details (Type, Version,
              Model, Operating System). We are using the information collected
              by UXCam to improve our app. UXCam stores and processes this
              information on our behalf and may contain minimal personally
              identifiable information although we proactively remove it
              wherever possible.
            </p>
            <h2>
              Does CredenceRewards.com Share/Disclose the Information It
              Receives?
            </h2>
            <p>
              CredenceRewards.com does not share your information with any other
              party. We only use the information internally for our services and
              the Applications as described above. CredenceRewards.com will not
              sell your personal information to third parties.
            </p>
            <p>
              In certain cases, we may disclose customer information if required
              to do so by law or in the good-faith belief that such action is
              necessary to comply with applicable laws or respond to a court
              order, judicial or other government subpoena, or warrant.
            </p>
            <p>
              If your purchase requires a third party service, your personal
              information will be shared with the service providers (Tabby for
              BNPL), so that they may provide services that are consistent with
              the terms of this Privacy Policy and our Platform.
            </p>
            <p>
              We also reserve the right to disclose customer information that we
              believe, in good faith, is appropriate or necessary to take
              precautions to protect customers from fraudulent, abusive, or
              unlawful uses; to investigate and defend ourselves against any
              third-party claims or allegations; to assist government
              enforcement agencies; to protect the security or integrity of the
              Applications; and to protect against fraudulent uses, the rights,
              property, or personal safety of CredenceRewards.com, our Clients,
              or others.
            </p>
            <h2>How Secure Is your Information with CredenceRewards.com?</h2>
            <p>
              CredenceRewards.com is committed to protecting the Personal Data
              you share with us. We utilize a combination of industry-standard
              security technologies, procedures, and organizational measures to
              help protect your Personal Data from unauthorized access, use or
              disclosure.
            </p>
            <ul>
              <li>
                We work to protect the security of your information during
                transmission by using Secure Sockets Layer (SSL) software, which
                encrypts information you input..
              </li>
              <li>
                We reveal only the last four digits of your credit/debit card
                numbers when confirming an order. Of course, we transmit the
                entire credit/debit card number to the appropriate credit/debit
                card company during order processing. We do not store this
                information in our Applications.
              </li>
              <li>
                It is important for you to protect against unauthorized access
                to your password and to your computer. Be sure to sign off when
                finished using a shared computer.
              </li>
              <li>
                If you use the Applications with Facebook make sure you either
                log out from Facebook or through the Applications (which will
                also log you out of Facebook also) if you are using a shared
                computer.
              </li>
            </ul>
            <p>
              If you have any questions about the security of your Personal
              Data, you can contact us at{" "}
              <a
                className="border-bottom border-danger"
                href="mailto:support@credencerewards.com"
              >
                support@credencerewards.com
              </a>
              .
            </p>
            <h2>What Choices Do You Have?</h2>
            <p>
              Our goal is to give you simple and meaningful choices regarding
              your information. If you have a CredenceRewards.com account, many
              of the choices you have on CredenceRewards.com are built directly
              into CredenceRewards.com or your settings. For example, you can:
            </p>
            <ul>
              <li>
                Edit personal information i.e. name and mobile phone number in
                your profile at any time.
              </li>
              <li>
                As stated previously, you can always opt not to disclose
                information, even though it may be needed to take advantage of
                or register for certain features of the Applications.
              </li>
              <li>
                You may request deletion of your Applications account by sending
                an e-mail to:&nbsp;
                <a
                  className="border-bottom border-danger"
                  href="mailto:support@credencerewards.com"
                >
                  support@credencerewards.com
                </a>
                .
              </li>
              <li>
                In certain cases, if you decline to share personal information
                on the Applications, CredenceRewards.com will not be able to
                provide certain services to you. You may also choose to opt-out
                of future marketing mailings by following the opt-out
                instructions in the emails that we send to you.
              </li>
            </ul>
            <h2>Conditions of Use, Notices, and Revisions</h2>
            <p>
              If you use the Applications, your use and any dispute over privacy
              is subject to this Privacy Policy, our Terms of Use, including
              limitations on damages, arbitration of disputes, and Applications
              of the law of the United Arab Emirates. If you have any concern
              about privacy at CredenceRewards.com, please contact us with a
              thorough description, and we will try our best to resolve it. Our
              business changes constantly, and our Privacy Policy and the Terms
              of Use Agreement will change also. You should check our website to
              see recent changes. Unless stated otherwise, our current Privacy
              Policy applies to all information that we have about you and your
              account.
            </p>
            <h2>How we transfer information we collect internationally</h2>
            <p>
              We collect information from all our users and store that
              information in the United Arab Emirates and United States of
              America. We transfer, process and store your information outside
              of your country of residence, to wherever we operate for the
              purpose of providing you the services. Whenever we transfer your
              information, we take steps to completely protect it.
            </p>
            <h2>Your Right to Access Your Data</h2>
            <p>
              You have the below mentioned options in relation to the
              information that we have collected about you. To exercise these
              options, please contact us at{" "}
              <a href="mailto:support@credencerewards.com">
                support@credencerewards.com
              </a>
              . If you're an European Union (EU) user, you can also:
            </p>
            <ul>
              <li>
                Access the information we hold about you. We'll usually share
                this with you within 30 days of you asking us for it.
              </li>
              <li>
                Have your information corrected or deleted. You can update your
                information in your settings. If you have problems updating the
                information or if you would like us to delete it, contact us at
                <a
                  className="border-bottom border-danger"
                  href="mailto:support@credencerewards.com"
                >
                  support@credencerewards.com
                </a>
                .
              </li>
              <li>
                Object to us processing your information. You can ask us to stop
                using your information, including when we use your information
                to send you marketing emails or push notifications. We will only
                send you marketing material if you've agreed to it, but if you'd
                rather we don't, you can easily unsubscribe at any time.
              </li>
              <li>
                Have the information you provided to us sent to another
                organization, where we hold this information with your consent
                or for the performance of a contract with you, where it's
                technically feasible.
              </li>
            </ul>
            <p>
              We retain the data we collect for as long as needed to provide you
              services and to maintain adequate records as per the law of
              country where we operate, resolve disputes, and meet our
              regulatory obligations.
            </p>
            <h2>Children's Privacy</h2>
            <p>
              The Applications of CredenceRewards.com are not structured to
              attract children. Accordingly, we do not intend to, nor will we
              knowingly collect personal information from anyone we know to be
              under 16 years of age. If you become aware that a child has
              provided us with personal information, please contact at{" "}
              <a href="mailto:support@credencerewards.com">
                support@credencerewards.com
              </a>{" "}
              with our support services.
            </p>
            <h2>Changes to this Privacy Policy</h2>
            <p>
              CredenceRewards.com may amend this Privacy Policy from time to
              time, and at any time. Use of information we collect now is
              subject to this Privacy Policy in effect at the time such
              information is used. If we make changes to this Privacy Policy or
              in the way we use the Personal Information, we will notify you by
              posting an announcement on our website or sending you an email.
              Your continued use of our Applications makes you bound by any
              changes to the Privacy Policy, whenever you use the our
              Applications after such changes have been first posted.
            </p>
            <h2>Contact Us</h2>
            <p>
              If you have questions or concerns about how your information is
              handled, please direct your inquiry to CredenceRewards.com via
              email at{" "}
              <a
                className="border-bottom border-danger"
                href="mailto:support@credencerewards.com"
              >
                support@credencerewards.com
              </a>
              .
            </p>
            <address>
              <strong>CredenceRewards.com Ltd.</strong>
              <br />
              Credence Rewards Private Limited
              <br />
              C/ 506 Neelkanth Business Park, Nathani Road,
              <br />
              Vidyavihar West, Mumbai- 40008
              <br />
            </address>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default PrivacyPolicy