import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

function Slider() {
  const navigate = useNavigate();
  return (
    <>
      <Swiper
        spaceBetween={50}
        breakpoints={{
          // when window width is <= 768px
          768: {
            slidesPerView: 1,
            centeredSlides: false,
          },
          // when window width is > 768px
          1200: {
            slidesPerView: 1.2,
            centeredSlides: true,
          },
        }}
        loop={true}
        slidesOffsetAfter={0}
        // centeredSlidesBounds={true}
        autoplay={{
          delay: 2500, // Autoplay delay in milliseconds
          speed: 3200,
          freeMode: true,
          disableOnInteraction: false, // Allow manual navigation while autoplay is active
        }}
        modules={[Navigation, Autoplay]}
        style={{ transition: "all" }}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        className="banner_section slide_medium shop_banner_slider staggered-animation-wrap"
      >
        <SwiperSlide>
          {" "}
          <div onClick={() => navigate("/nykaa-b2b-gift-card")}>
            <img
              src="assets/index-banner/Nykaa.png"
              width="100%"
              style={{ height: "inherit", borderRadius: "20px" }}
              alt="site-banner"
              className="desktop "
            />
            <img
              src="assets/index-banner/Nykaa.png"
              style={{ height: "213px" }}
              alt="site-banner"
              className="mobile"
            />
          </div>
        </SwiperSlide>
         {/* <SwiperSlide>
          {" "}
          <div onClick={() => navigate("/brand/36/category/Women")}>
            <img
              src="assets/index-banner/Father.png"
              width="100%"
              style={{ height: "inherit", borderRadius: "20px" }}
              alt="site-banner"
              className="desktop "
            />
            <img
              src="assets/index-banner/Father.png"
              style={{ height: "213px" }}
              alt="site-banner"
              className="mobile"
            />
          </div>
        </SwiperSlide> */}
       
       
        <SwiperSlide>
          {" "}
          <div onClick={() => navigate("/brand/36/category/Women")}>
            <img
              src="assets/index-banner/loved_web1.png"
              width="100%"
              style={{ height: "inherit", borderRadius: "20px" }}
              alt="site-banner"
              className="desktop "
            />
            <img
              src="assets/index-banner/Shop.png"
              style={{ height: "213px" }}
              alt="site-banner"
              className="mobile"
            />
          
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div onClick={() => navigate("/brand/7/category/Travel")}>
            <img
              src="assets/index-banner/yatra.png"
              width="100%"
              style={{ height: "inherit", borderRadius: "20px" }}
              alt="site-banner"
              className="desktop rounded-lg"
            />
            <img
              src="assets/index-banner/yatra.png"
              style={{ height: "213px" }}
              alt="site-banner"
              className="mobile"
            />
           
          </div>
        </SwiperSlide>
      
      
      
        <SwiperSlide>
          {" "}
          <div onClick={() => navigate("/brand/9/category/Entertainment")}>
            <img
              src="assets/index-banner/Movie.png"
              width="100%"
              style={{ height: "inherit", borderRadius: "20px" }}
              alt="site-banner"
              className="desktop"
            />
            <img
              src="assets/index-banner/Movie_mob.png"
              style={{ height: "213px" }}
              alt="site-banner"
              className="mobile"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div onClick={() => navigate("/contact-us")}>
            <img
              src="assets/index-banner/Prepaid_web.png"
              width="100%"
              style={{ height: "inherit", borderRadius: "20px" }}
              alt="site-banner"
              className="desktop"
            />
            <img
              src="assets/index-banner/Prepaid_card.png"
              style={{ height: "213px" }}
              alt="site-banner"
              className="mobile"
            />
            {/* <div className="banner_slide_content">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-12 text-center">
                      <div className="banner_content overflow-hidden">
                        <h2
                          className="staggered-animation text-dark"
                          data-animation="slideInLeft"
                          data-animation-delay="1s"
                        >
                          Best Prepaid Card
                        </h2>
                        <h5
                          className="mb-3 text-dark staggered-animation font-weight-light"
                          data-animation="slideInLeft"
                          data-animation-delay="0.5s"
                        >
                          Solve all your Gifting needs with <br /> Credence
                          Rewards NSDL Prepaid Card.
                        </h5>
                        <Link
                          className="btn btn-fill-out rounded-0 staggered-animation text-uppercase text-white mt-5"
                          to="/contact-us"
                          data-animation="slideInLeft"
                          data-animation-delay="1.5s"
                        >
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </SwiperSlide>
        {/* <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div> */}
      </Swiper>
    </>
  );
}

export default Slider;
