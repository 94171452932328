import React, { useEffect } from "react";

function TermsAndCondition() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scroll effect
    });
  }, []);
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="term_conditions">
                <h1 className="text-center mt-3">
                  Terms and Conditions - Credence Rewards
                </h1>
                <div className="page-content-block">
                  <p>
                    Welcome to Credence Rewards, an online gift card platform,
                    incorporated under the laws of India, with its office at C/
                    506 Neelkanth Business Park, Nathani Road, Vidyavihar West,
                    Mumbai- 400086 India. Your use of this website and its
                    services is subject to the following terms and conditions
                    ("Terms"), along with any amendments made by Credence
                    Rewards at its sole discretion and posted on the website.
                  </p>
                  <h3>1. Acceptance of Terms:</h3>
                  <p>
                    By accessing and using the Credence Rewards website
                    ("Website"), you hereby agree to be bound by these Terms in
                    their entirety. If you do not agree with any part of these
                    Terms, please refrain from using this Website.
                  </p>
                  <h3>2. Privacy Policy:</h3>
                  <p>
                    When you choose to use our services to send a Gift Card to
                    anyone, you represent that you have their consent to provide
                    us their name, email address and/or mobile number. We will
                    automatically send the receiver of Gift Card an email or
                    mobile SMS informing them of the received Gift Card. Unless
                    we are authorized by your friend, we will only use your
                    friend’s name, email address and/or mobile number for the
                    purposes of sending this email and reminder emails for using
                    the Gift Cards and maintaining an activity log of it.{" "}
                  </p>
                  <p>
                    Your privacy is important to us. Please read and fully
                    understand the Privacy Policy of Credence Rewards, which
                    governs the use and collection of your personal information.
                    You can find the Privacy Policy at{" "}
                    <a
                      className="border-bottom border-danger"
                      href="privacy-policy.html"
                    >
                      https://www.credencerewards.com/privacy-policy
                    </a>
                    .
                  </p>
                  <h3>3. Gift Card Services:</h3>
                  <p>
                    Credence Rewards provides an online platform to explore and
                    purchase gift cards from multiple brands. We offer gift
                    cards for businesses and individual customers, without any
                    hidden charges, and ensure instant delivery for a seamless
                    experience.
                  </p>
                  <h3>4. Eligibility and User Obligations:</h3>
                  <p>
                    You must be at least 16 years old to use this Website. By
                    using the Website, you agree and undertake to:
                  </p>
                  <ul>
                    <li>
                      Use the Website for legitimate and lawful purposes only.
                    </li>
                    <li>
                      Abide by all applicable laws and regulations in India
                      while using the services provided by Credence Rewards.
                    </li>
                    <li>
                      Refrain from any unauthorized and illegal activities while
                      using the Website, as specified in these Terms.
                    </li>
                    <li>
                      Keep your login credentials and personal information
                      secure and not share them with any third parties.
                    </li>
                  </ul>
                  <h3>5. Intellectual Property Rights:</h3>
                  <p>
                    Credence Rewards owns all Intellectual Property Rights to
                    the trademarks, content, technology platform, and underlying
                    software of the Website. You shall not use, reproduce, or
                    distribute any content from the Website belonging to
                    Credence Rewards without obtaining authorization.
                  </p>
                  <h3>6. Liability and Indemnification:</h3>
                  <p>
                    While we make every effort to ensure the accuracy and
                    quality of information on the Website, Credence Rewards does
                    not warrant or represent the completeness or reliability of
                    any data, product, or service. You agree to indemnify and
                    hold Credence Rewards harmless from any losses, liabilities,
                    claims, damages, costs, and expenses arising from your use
                    of the Website.
                  </p>
                  <h3>7. Termination:</h3>
                  <p>
                    Credence Rewards reserves the right to suspend or terminate
                    your use of the Website at its sole discretion. These Terms
                    will remain in effect unless and until Credence Rewards
                    chooses to terminate them.
                  </p>
                  <h3>8. Refund Policy:</h3>
                  <p>
                    Gift Vouchers once sold from the Credence Rewards website
                    will not be cancelled or refunded.
                  </p>
                  <h3>9. Governing Law and Jurisdiction:</h3>
                  <p>
                    These Terms shall be governed by and construed in accordance
                    with the laws of India. Disputes arising in relation to
                    these Terms shall be subject to the exclusive jurisdiction
                    of the courts at Mumbai.
                  </p>
                  <h3>10. Modifications to Terms:</h3>
                  <p>
                    Credence Rewards may update or modify these Terms at any
                    time without prior notice. It is your responsibility to
                    review these Terms periodically for any changes.
                  </p>
                  <h3>11. Contact Us:</h3>
                  By using Credence Rewards, you acknowledge that you have read,
                  understood, and agreed to these Terms. Thank you for choosing
                  Credence Rewards as your preferred gift card provider.
                  <p />
                  <p>
                    If you have questions or concerns about how your information
                    is handled, please direct your inquiry to Credence
                    Rewards.com via email at{" "}
                    <a
                      className="border-bottom border-danger"
                      href="mailto:support@credencerewards.com"
                    >
                      support@credencerewards.com
                    </a>
                    .
                  </p>
                  <address>
                    <strong>Credencerewards.com Ltd.</strong>
                    <br />
                    Credence Rewards Private Limited
                    <br />
                    C/ 506 Neelkanth Business Park, Nathani Road,
                    <br />
                    Vidyavihar West, Mumbai- 400086
                    <br />
                  </address>
                
                </div>
              </div>
              <p className="text-muted text-center ">
                <strong>Note: </strong>
                <span className="border-bottom border-danger">
                  Brand giftcard t&amp;c's is displayed in brand details and
                  checkout page
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndCondition;
