import React, { useEffect, useState } from "react";
import SubscribeSection from "./Homepage/SubscribeSection";
import * as Yup from "yup";
import { useFormik } from "formik";
import { checkBalance } from "../services/Apiservices";
import showNotification from "../services/NotificationService";

function CheckBalance() {
  const [loading, setisLoading] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scroll effect
    });
  }, []);
  const [data, setData] = useState("");
  const [showCard, setShowCard] = useState(false);

  const validationSchema = Yup.object({
    code: Yup.string()
      .required("Card number is required")
      .min(16, "Card number must be at least 16 digits"),
    pin: Yup.string().required("Pin is required"),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
      pin: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      handleCheckBalance(values);
    },
  });
  const handleCheckBalance = async (values) => {
    setisLoading(true);
    let body = {
      code: values?.code,
      pin: values?.pin,
    };
    try {
      let response = await checkBalance(body);
      if (response?.status == 200) {
        formik.handleReset();

        setShowCard(true);
        setData(response?.data?.data);
        setisLoading(false);
      } else {
        setShowCard(false);
        setisLoading(false);
      }
    } catch (error) {
      setShowCard(false);
      setisLoading(false);
    }
  };
  return (
    <>
      <div className="main_content ">
        {/* START SECTION SHOP */}
        <div className="section ">
          <div className="container">
            <div className="heading_s1 row text-center">
              <h2>CHECK GIFT-CARD BALANCE</h2>
            </div>
            <div className="row mt-1">
              <div className="col-lg-5 block">
                <div className="single_img ">
                  <img
                    className=" mb-4 rotated"
                    width="70%"
                    src="../assets/imgs/check.png"
                    alt="Card Image"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <p className="leads">
                  <small>
                    To view your card balance enter the 16 digit card number and
                    security code(PIN) on the back of your gift card.
                  </small>
                </p>
                <div className="field_form">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="form-group col-md-12 mb-3">
                        <input
                          className="form-control"
                          minLength={16}
                          maxLength={20}
                          name="code"
                          placeholder="Card Number"
                          id="gift_card_no"
                          autoComplete="off"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.code}
                        />
                        {formik.touched.code && formik.errors.code ? (
                          <div className="text-danger">
                            {formik.errors.code}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-12 mb-3">
                        <input
                          className="form-control"
                          minLength={6}
                          maxLength={20}
                          name="pin"
                          placeholder="Card Pin"
                          id="gift_card_pin"
                          autoComplete="off"
                          type="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.pin}
                        />
                        {formik.touched.pin && formik.errors.pin ? (
                          <div className="text-danger">{formik.errors.pin}</div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-12 mb-3">
                        <div className="error capcha-error-msg " />
                        <div className="input-group single-field col-12">
                          <div
                            className="g-recaptcha"
                            data-sitekey="6LcrwhsmAAAAAH8-1aEACPdZrORnP25k_qqZGYd9"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <button
                          type="button"
                          title="Reset this form!"
                          className="btn btn-fill-line mr-4"
                          name="reset"
                          value="Reset"
                          onClick={formik.handleReset}
                        >
                          Reset
                        </button>
                        {loading ? (
                          <button
                            type="button"
                            title="Check Balance!"
                            className="btn btn-fill-out "
                            name="submit"
                            value="Submit"
                          >
                            Processing...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            title="Check Balance!"
                            className="btn btn-fill-out "
                            name="submit"
                            value="Submit"
                          >
                            Check
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                {showCard && (
                  <div className="col-lg-12 bg-light bal-div">
                    <div className="col-lg-12  d-flex justify-content-center">
                      <div className="checkout-form-list col-lg-12">
                        <table
                          className="table text-center"
                          style={{ borderBottom: "1px solid lightgray" }}
                        >
                          <tbody id="bal-info">
                          {
                           data?.CardNumber ? <tr>
                           <th scope="row">Card Number</th>
                           <td>{data?.CardNumber}</td>
                         </tr>: <tr>
                              <th scope="row">Message</th>
                              <td>{data?.message}</td>
                            </tr>
                          }
                           {
                            data?.balance &&   <tr>
                            <th scope="row">Balance</th>
                            <td>{data?.balance}</td>
                          </tr>
                           }

                               {
                            data?.expiry &&   <tr>
                            <th scope="row">Expiry</th>
                            <td>{data?.expiry}</td>
                          </tr>
                           }
                          
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className="row small ">
              <p className="text-muted text-center">
                This site is protected by ReCaptcha. Recaptcha is governed by
                the Google Privacy Policy and Terms Of Use.
              </p>
              <p>
                <i />
                <b>Note :</b> To view your card balance enter the 16 digit card
                number and the 6 digit security code(PIN) on the back of your
                gift card. if any issue found in your balance or card, Pease
                contact your card provider or contact your brand store for which
                the card belongs.
              </p>
            </div>
          </div>
        </div>
        {/* END SECTION SHOP */}
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
        <SubscribeSection />
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
      </div>
      {loading && (
      <div class="loader-container" id="loader-container">
    <div class="loader"></div>
  </div>
      )}
    </>
  );
}

export default CheckBalance;
